/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 10px 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/************ Colours Css ************/
:root {
  --white: #FFFFF3; /* ivory */
  --teal: #087E8B;
  --accent: #F48668; /* coral */
  --pink: #D88C9A; 
  --khaki: #B7AD99; /* khaki */
  --blue: #17183B; /* space cadet */
  --gray: #A0A3A3;
  --brown: #461220; /* chocolate cosmos */ 
  --wolf: #1e6194;
  --ice: #9FFFF5; /* ice blue */
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  background: var(--accent);
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: var(--white);
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: var(--brown) !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  /* background: var(--ice); */
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
    opacity: 1;
    color: var(--blue) !important;
}
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    color: var(--blue) !important;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
    color: var(--brown);
}
.social-icon a {
    width: 50px;
    height: 50px;
    background: transparent;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    /* border: 1px solid var(--blue); */
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: var(--ice);
    border-radius: 50%;
    transform: scale(0);
	  transition: 0.3s ease-in-out;
    
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: var(--blue);
  /* border: 1px solid #fff; */
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--ice);
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: var(--blue);
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
  border: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--brown);
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid var(--brown);
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: var(--brown);
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	/* transform: rotate(45deg); */
	background-color: var(--white);
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	/* transform: translateY(8px) rotate(-45deg); */
	background-color: var(--brown);
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: var(--brown);
}
.navbar .vvd {
  background-color: transparent; /* Adjust as needed */
  color: var(--brown); /* Adjust as needed */
  border: 2px solid var(--brown) !important; /* Add border */
  border-radius: 15px; /* Optional: Add border radius */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/************ Banner Css ************/
.banner-screen {
  margin-top: 0;
  padding-left: 300px;
  padding-right: 20px;
  height: calc(100vh - 100px); /* Set height to full viewport height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: var(--white);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .align-items-center {
  margin-top: 0;
  background-color: var(--ice);
  background-position: top center;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90deg, var(--accent) -5.91%, var(--wolf));
  border: 1px solid var(--blue);
  font-size: 20px;
  margin-bottom: 30px;
  display: inline-block;
}
.banner h1 {
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 30px;
  display: block;
  color: var(--blue);
}
.banner p {
  color: var(--wolf);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: var(--brown);
  border: 2px var(--brown);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
.banner-button .vvd {
  background-color: transparent; /* Adjust as needed */
  color: var(--brown); /* Adjust as needed */
  border: 2px solid var(--brown) !important; /* Add border */
  border-radius: 15px; /* Optional: Add border radius */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.banner-button .vvd:hover {
  background-color: var(--ice);
}
.banner .banner-image {
  position: absolute;
  top: -290px;
  right: -100px;
  width: 900px; /* Adjust the width as needed */
  height: auto;
  opacity: 0.05;
}

@keyframes updown {
    0% {
        transform: translateY(-0px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-0px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* Media query for mobile devices */
@media (max-width: 768px) {

  .navbar .logo img{
    width: 60px !important;
    height: 57px !important;
  }
  .banner-screen {
    padding: 80px 20px 50px 20px !important; /* Adjust padding for mobile */
  }
  .banner h1 {
    font-size: 35px; /* Adjust font size for mobile */
    margin-bottom: 30px;
  }
  .banner p {
    font-size: 14px; /* Adjust font size for mobile */
  }
  .banner .tagline {
    font-size: 16px; /* Adjust tagline font size for mobile */
  }
  .banner .banner-image {
    position: absolute;
    top: -250px;
    right: -150px;
    width: 550px; /* Adjust the width as needed */
    height: auto;
    opacity: 0.05;
  }

  /* Mobile project tabs styles */
  .project h2 {
    font-size: 25px !important; /* Adjust font size for mobile */
    margin-top: -40px;
    margin-bottom: 20px;
  }
  .project .nav.nav-pills{
    /* margin: 0 0px !important; */
    width: 90% !important;
  }
  .project .nav.nav-pills .nav-link {
    font-size: 14px !important; /* Adjust the font size for mobile */
    padding: 10px 0px !important; 
    width: auto !important; /* Make the pills wider */
    /* min-width: 120px; Ensure a minimum width */
    text-align: center; /* Center the text */
  }
  .project .nav.nav-pills .nav-link.active::before {
    width: 50px !important;
  }
  .project .proj-imgbx {
    margin-left: 25px !important;
    margin-right: 25px !important;
    height: 250px !important;
  }
  .project .proj-imgbx img {
    height: 250px !important;
  }

  .about-education-content,
  .about-personal-content {
    width: 100%;
    padding-right: 0px !important;
  }
  .about-section {
    padding: 30px 20px 40px 20px !important;
    margin-left: 0px !important;
  }
  .about-section .slideshow {
    width: 90% !important;
    margin: 20px 0 0 15px !important;
  }
  .about-section .slideshow img {
    height: 270px !important;
  }
  .about-education-content p,
  .about-personal-content p{ 
    font-size: 15px;
  }
}

/************ About Css ************/
.about-section {
  padding: 30px 80px 50px 0px;
  background-color: var(--ice);
  margin-left: -200px;
  
}
.about-section .slideshow {
  width: 130%;
}
.slideshow img {
  width: 100%; /* Ensure the image takes the full width of the container */
  height: 350px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure the image covers the container without distortion */
}

.education h2, .personal h2 {
  color: var(--blue);
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase; 
  
  margin-top: 30px;
  margin-bottom: 25px;
}
.education h2 {
  margin-top: 50px;
}

.education p, .personal p {
  color: var(--brown);
  margin-top: -5px;
  padding-right: 20px;
}

.about-content {
  display: flex;
  flex-direction: column;
}

.about-education-content,
.about-personal-content {
  width: 100%;
  padding-right: 20px;
}
.about-personal-content {
  margin-bottom: 20px;
}

.carousel img {
  border-radius: 10px;
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
}


/* Custom Modal Styles */
.modal .carousel img {
  border-radius: 0; /* Remove border radius for larger view */
}
.custom-modal .modal-content {
  background-color: var(--ice); /* Change background color */
  border-radius: 20px; /* Add border radius */
}
.custom-modal-header {
  border-bottom: none; /* Remove border bottom */
}
.custom-modal-body {
  padding: 20px; /* Add padding */
  border-bottom: none;
}
.custom-modal-body img {
  width: 100%; /* Ensure the image takes the full width of the container */
  height: 600px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure the image covers the container without distortion */
}
.custom-modal-footer {
  border: none; /* Remove border top */
  display: flex;
  justify-content: center; /* Center the button */
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: var(--white);
}
.project h2 {
	font-size: 30px;
	font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: var(--blue);
  margin-bottom: 20px;
}
.project p {
  color: var(--brown);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: transparent;
  overflow: hidden;
  cursor: pointer;
}
.project .nav.nav-pills :hover{
  background-color: var(--ice);
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: var(--white);
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: var(--accent);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 2px solid var(--blue);
}
.nav-link#projects-tabs-tab-first {
  border: 2px solid var(--blue);
  border-radius: 55px 0px 0px 55px;
  color: var(--blue);
}
.nav-link#projects-tabs-tab-second {
  border-top: 2px solid var(--blue);
  border-bottom: 2px solid var(--blue);
  color: var(--blue);
}
.nav-link#projects-tabs-tab-third {
  border: 2px solid var(--blue);
  border-radius: 0 55px 55px 0;
  color: var(--blue);
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 300px;
}
.proj-imgbx img {
  height: 300px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(0deg, var(--accent), var(--wolf));
  opacity: 0.95;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-imgbx-linkbx {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  margin-top: '100px';
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 60px 30px 20px 30px;
}
.proj-imgbx .external-link:hover{
  background-color: var(--blue);
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.proj-txtx h5 {
  margin-bottom: 40px;
}
/* Scrollable image gallery */
.image-gallery {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  position: relative; /* Ensure the pseudo-element is positioned correctly */
}
.image-gallery img {
  max-height: 250px; /* Adjust the height as needed */
  width: auto;
  margin-right: 10px;
  border-radius: 10px;
}
/* Hide scrollbar */
.image-gallery {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.image-gallery::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  width: 300px; /* Adjust the width as needed */
  height: 55%; /* Full height of the container */
  /* background: linear-gradient(to left, var(--ice) 0%, transparent); Gradient from ice blue to transparent */
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with scrolling */
  /* z-index: 1; Ensure the pseudo-element is behind the images */
}

@media (max-width: 600px) {
  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    max-height: 300px;
  }
  .proj-imgbx h3 {
    font-size: 25px !important;
    margin-top: -20px;
  }
  .proj-imgbx h5 {
    font-size: 18px !important;
    margin-bottom: 20px;
  }

  .modal-main-content {
    width: 90% !important; /* Adjust width to fit mobile screen */
    height: auto !important; /* Adjust height as needed */
    padding: 20px !important; /* Add padding for better spacing */
    overflow-y: auto !important; /* Enable scrolling if content overflows */
  }
  .image-gallery img {
    height: 200px !important; 
    width: auto !important;
  }
  .custom-modal-body img {
    width: 100% !important; /* Ensure the image takes the full width of the container */
    height: 420px !important; /* Set a fixed height for the images */
    object-fit: cover !important; /* Ensure the image covers the container without distortion */
  }
  .custom-modal-footer button {
    display: none !important; /* Hide the button */
  }
  .modal-main-content button {
    padding: 0 !important;
    display: none !important; /* Hide the button */
  }
  .modal-main-content h2 {
    font-size: 25px !important; /* Adjust font size for mobile */
  }
  .modal-main-content h3 {
    font-size: 15px !important; /* Adjust font size for mobile */
    padding: 0 !important; /* Remove margin */
    margin: 0 !important; /* Remove padding */
    margin-bottom: 18px !important; /* Add margin bottom */
    text-align: left !important; /* Align text to the left */
  }
  .modal-main-content .external-links {
    margin-bottom: 10px !important; /* Add margin bottom */
    margin-top: 0 !important; /* Remove margin top */
    /* padding: 0 !important; Remove padding */
  }
  .modal-main-content ul {
    margin-bottom: 40px !important; /* Add margin bottom */
  }
  .navbar-nav .nav-link.navbar-link {
    padding: 7px 0px 4px 20px !important; /* Adjust padding for mobile */
    font-size: 20px !important; /* Adjust font size for mobile */
  }
}

/* Add this to your CSS file */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.ReactModal__Content {
  position: absolute;
  background: rgb(17, 238, 254);
  margin-top: 50px;
  outline: none;
}

/************ Skills Css ************/
.skill {
  padding: 50px 0 50px 0;
  position: relative;
  background: var(--ice);
}
.skill-bx {
  background: transparent;
  border-radius: 64px;
  text-align: center;
  padding: 20px 0px;
}
.skill h2 {
	font-size: 30px;
	font-weight: 900;
  color: var(--blue);
  text-transform: uppercase;
}
.skill p {
    color: var(--brown);
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill .item h5 {
  color: var(--brown);
  font-weight: 500;
  font-size: 15px;
}
.skill .item {
  padding: 0 0px;
}
.skill-slider {
  width: 70%;
  margin: 0 auto;
  position: relative;
  padding-top: 25px;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.skill .react-multiple-carousel__arrow--left {
  left: 0px; /* Adjust this value to move the left arrow further left */
  background-color: var(--accent);
  opacity: 0.3;
}

.skill .react-multiple-carousel__arrow--right {
  right: 0px; /* Adjust this value to move the right arrow further right */
  background-color: var(--accent);
  opacity: 0.3;
}
.react-multi-carousel-track {
  margin: -10px; /* Remove margin from the carousel track */
}


/* Media query for mobile devices */
@media (max-width: 768px) {
  .skill {
    padding: 20px 20px;
  }
  .skill h2 {
    font-size: 25px; /* Adjust font size for mobile */
  }
  .skill .item h5 {
    font-size: 14px; /* Adjust font size for mobile */
  }
  .skill p {
    font-size: 14px; /* Adjust font size for mobile */
  }
  .skill-slider {
    width: 100%;
    padding-top: 5px;
  }
  .skill-slider .item img {
    width: 40%; /* Adjust image width for mobile */
  }
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 0;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 70px 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: var(--white);
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: var(--accent);
  letter-spacing: 0.5px;
  margin-top: 20px;
}
